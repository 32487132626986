import React, {Component, FormEvent, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import { images } from '../../../assets/image'
import { routes } from '../../../routes/routesConstants'
import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import './signup.scss';
import './react-phone-number.css';
import {APP_URL, PAYMENT_METHOD} from "../../../environment";
import {authService} from "../../../services/auth.service";
import Phone from 'react-phone-number-input'
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
// @ts-ignore
import { useAlert } from "react-alert";



interface UserData {
    firstName:string,
    email:string,
    user_name:string,
    password:string,
    password_confirmation:string,
    phone:any,
    gender:string,
    age:any,
    position:string,
    payment_method:string,
    cardData:any,
    agreement:any,
}

export const SignupUser = () => {
    const [membershipDuration, setMembershipDuration] = useState('monthly');
    const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.chargebee);
    const [cardData, setCardData] = useState({
        cardNumber:'',
        expiry:'',
        cvc:'',
        error:false
    });
    const [errors, setErrors] = useState({
        agreement:false
    });
    const [userData, setUserData] = useState<UserData>({
        firstName:'',
        email:'',
        password:'',
        password_confirmation:'',
        phone:'',
        user_name:'',
        gender:'',
        age:'',
        position:'',
        payment_method: paymentMethod,
        cardData : cardData,
        agreement:false
    });

    const [monthlyPlan, setMonthlyPlan] = useState({
        name:undefined,
        price:''
    });

    const [yearlyPlan, setYearlyPlan] = useState({
        name:undefined,
        price:''
    });

    const [formErrors,setFormErrors] = useState({
        firstName:'',
        email:'',
        password:'',
        password_confirmation:'',
        phone:'',
        user_name:'',
        gender:'',
        age:'',
        position:'',
    })

    const alert = useAlert();

    let validateInput = () => {
        let isError = false;
        let errors = {
            firstName:'',
            email:'',
            password:'',
            password_confirmation:'',
            phone:'',
            user_name:'',
            gender:'',
            age:'',
            position:'',
        };

        if (userData.firstName == ''){
            errors.firstName = 'Field is required';
            isError = true;
        }
        if (userData.email == ''){
            errors.email = 'Field is required';
            isError = true;
        }
        if (userData.password == ''){
            errors.password = 'Field is required';
            isError = true;
        }else {
            if (userData.password !== userData.password_confirmation) {
                errors.password = 'Password confirmation does not match';
                isError = true;
            }
        }
        if (userData.phone == ''){
            errors.phone = 'Field is required';
            isError = true;
        }
        if (userData.user_name == ''){
            errors.user_name = 'Field is required';
            isError = true;
        }
        if (userData.position == ''){
            errors.position = 'Field is required';
            isError = true;
        }

        if (userData.age == ''){
            errors.age = 'Field is required';
            isError = true;
        }

        if (userData.gender == ''){
            errors.gender = 'Field is required';
            isError = true;
        }

        setFormErrors(errors)
        return isError;
    }
    let signUp = (e : FormEvent) : void => {
        e.preventDefault();
        console.log('test')
        if (!userData.agreement){
            alert.error('Please accept agreement')
            setErrors({...errors,agreement: true})
            // @ts-ignore
            return 0;
        }
        let validationStatus = validateInput();
        if (validationStatus){
            // @ts-ignore
            return 0;
        }
        setUserData({...userData,payment_method:paymentMethod})
        setUserData({...userData,cardData:cardData})
        userData.payment_method = paymentMethod;
        userData.cardData = cardData;
        console.log(userData)
        authService.signup(userData).then(async ({data}) => {
            console.log(data)
            if (data.status_code == 200){
                if (paymentMethod == PAYMENT_METHOD.paypal){
                    await authService.setPaypalUserId(data.user_id);
                    window.location = data.redirect_url
                }else{
                    authService.authenticate(data.data)
                    // @ts-ignore
                    window.location = APP_URL+routes.dashboard
                }

            }else{
                if (data.status == 'error'){
                    alert.error(data.error)
                }

                if (data.status_code == 302){
                    alert.error(data.errors[0])

                }
            }
        })
    }

    let changeAgreement = (e : any) => {
        setUserData({...userData,agreement:e.target.checked})
    }

    useEffect(() => {
        authService.getPlans().then(({data}: any) => {
            if (data.status_code == 200){
                console.log(data)
                setMonthlyPlan(data.monthlyPlan)
                setYearlyPlan(data.yearlyPlan)
            }
        })
    }, []);


    return (
        <div className="rt-signupholder h-100">
            <div className="rt-heading">
                <h2>MEMBERSHIP</h2>
            </div>
            <div className="rt-membershipplan">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav className="rt-navtabs">
                        <Nav.Item>
                            <Nav.Link eventKey="first" onClick={() => setMembershipDuration('monthly')}>MONTHLY</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second" onClick={() => setMembershipDuration('yearly')}>YEARLY(SAVE 17.9%)</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div className="rt-tabcontentarea">
                                <div className="rt-themecardbox">
                                    <div className="rt-roundicon">
                                        <i className="icon-dollar"></i>
                                    </div>
                                    <div className="rt-themecontent">
                                        <span><strong>${parseInt(monthlyPlan.price) > 0 ? parseInt(monthlyPlan.price)/100 : '0.00'} </strong> Monthly Validity</span>
                                        <h4>{monthlyPlan.name ?? 'Loading...'}</h4>
                                        <em>Unlimited Programs, Sessions & Drills</em>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <div className="rt-tabcontentarea">
                                <div className="rt-themecardbox">
                                    <div className="rt-roundicon">
                                        <i className="icon-dollar"></i>
                                    </div>
                                    <div className="rt-themecontent">
                                        <span><strong>$197 </strong> Yearly Validity</span>
                                        <h4>MST Yearly</h4>
                                        <em>Unlimited Programs, Sessions & Drills</em>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            <div className="rt-formbox">
                <form className="rt-formtheme rt-forgotpasswordform" onSubmit={signUp}>
                    <fieldset>
                        {/* <div className="rt-logobox">
                            <strong className="rt-signuplogo"><Link to={routes.login}><img src={images.signuplogo} alt="" /></Link></strong>
                        </div> */}
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group text-center">
                                    <h3>ACCOUNT INFORMATION</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" id="name-field" placeholder="FIRST NAME" value={userData.firstName} onChange={e => setUserData({...userData,firstName:e.target.value})} />
                                        <label htmlFor="name-field">FULL NAME</label>
                                        <span style={{color:'red',fontSize:'12px',marginTop:'12px'}}>{formErrors.firstName}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="email" name="" className="form-control" id="email-field" placeholder="EMAIL" value={userData.email} onChange={e => setUserData({...userData,email:e.target.value})}/>
                                        <label htmlFor="email-field">EMAIL</label>
                                        <span style={{color:'red',fontSize:'12px',marginTop:'12px'}}>{formErrors.email}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="text" name="" className="form-control" id="name-field" placeholder="USERNAME" value={userData.user_name} onChange={e => setUserData({...userData,user_name:e.target.value})}/>
                                        <label htmlFor="name-field">USERNAME</label>
                                        <span style={{color:'red',fontSize:'12px',marginTop:'12px'}}>{formErrors.user_name}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        {/*<input type="number" name="" className="form-control" required id="number-field" placeholder="PHONE" value={userData.phone} onChange={e => setUserData({...userData,phone:e.target.value})}/>*/}
                                        {/*<label htmlFor="number-field">PHONE</label>*/}
                                        <Phone
                                            placeholder="Enter phone number"
                                            className="form-control"
                                            required
                                            value={ userData.phone }
                                            onChange={ phone =>  setUserData({...userData,phone:phone})} />
                                        <span style={{color:'red',fontSize:'12px',marginTop:'12px'}}>{formErrors.phone}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="password" name="" className="form-control" id="password-field" placeholder="PASSWORD" value={userData.password} onChange={e => setUserData({...userData,password:e.target.value})}/>
                                        <label htmlFor="password-field">PASSWORD</label>
                                        <span style={{color:'red',fontSize:'12px',marginTop:'12px'}}>{formErrors.password}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="password" name="" className="form-control" id="password-field" placeholder="CONFIRM PASSWORD" value={userData.password_confirmation} onChange={e => setUserData({...userData,password_confirmation:e.target.value})}/>
                                        <label htmlFor="password-field">CONFIRM PASSWORD</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <div className="rt-select">
                                            <select name="" className="form-control" id="gender-field" value={userData.gender} onChange={e => setUserData({...userData,gender:e.target.value})}>
                                                <option value="">Please Select</option>
                                                <option value="he">He</option>
                                                <option value="she">She</option>
                                                <option value="they">They</option>
                                            </select>
                                        </div>
                                        <label htmlFor="gender-field">GENDER</label>
                                        <span style={{color:'red',fontSize:'12px',marginTop:'12px'}}>{formErrors.gender}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <input type="number" name="" className="form-control" id="age-field" placeholder="AGE" value={userData.age} onChange={e => setUserData({...userData,age:e.target.value})}/>
                                        <label htmlFor="age-field">AGE</label>
                                        <span style={{color:'red',fontSize:'12px',marginTop:'12px'}}>{formErrors.age}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="form-group">
                                    <div className="rt-fieldholder">
                                        <div className="rt-select">
                                            <select name="" className="form-control" id="position-field" value={userData.position} onChange={e => setUserData({...userData,position:e.target.value})}>
                                                <option value="">Please Select</option>
                                                <option value="Goalkeeper">Goalkeeper</option>
                                                <option value="Defender">Defender</option>
                                                <option value="Midfielder">Midfielder</option>
                                                <option value="Forward">Forward</option>
                                            </select>
                                        </div>
                                        <label htmlFor="position-field">POSITION</label>
                                        <span style={{color:'red',fontSize:'12px',marginTop:'12px'}}>{formErrors.position}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group text-center mt-30">
                                    <h3>SELECT PAYMENT METHOD</h3>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-fieldholder rt-customradiobox">
                                        <div className="rt-customradio-area">
                                            <label className="rt-customradio">
                                                <input type="radio" name="paymentMethod" checked={paymentMethod == PAYMENT_METHOD.chargebee} value={PAYMENT_METHOD.chargebee} onChange={e => setPaymentMethod( e.target.value)}/>
                                                <span className="rt-checkmark"></span>
                                            </label>
                                            <span> CREDIT CARD</span>
                                        </div>
                                        <figure className="rt-cardsimage m-0">
                                            <img src={images.cards} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

                                <CreditCardInput
                                    onError={(err : any) => {
                                        setCardData({...cardData,error: true});
                                        console.log(err)
                                    }}
                                    cardNumberInputProps={{ value: cardData.cardNumber, onChange: (e: any) => setCardData({...cardData,cardNumber: e.target.value}) }}
                                    cardExpiryInputProps={{ value: cardData.expiry, onChange: (e: any) => setCardData({...cardData,expiry: e.target.value}) }}
                                    cardCVCInputProps={{ value: cardData.cvc, onChange: (e: any) => setCardData({...cardData,cvc: e.target.value}) }}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-fieldholder rt-customradiobox">
                                        <div className="rt-customradio-area">
                                            <label className="rt-customradio">
                                                <input type="radio" name="paymentMethod" value={PAYMENT_METHOD.paypal} onChange={e => setPaymentMethod(e.target.value)}/>
                                                <span className="rt-checkmark"></span>
                                            </label>
                                            <span>SIGN UP VIA PAYPAL</span>
                                        </div>
                                        <figure className="rt-cardsimage m-0">
                                            <img src={images.paymenticon} alt="" />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group">
                                    <div className="rt-customcheckbox-area">
                                        <label className="rt-customcheckbox">
                                            <input type="checkbox" value={userData.agreement} name="agreement" onChange={e => changeAgreement(e)}/>
                                            <span className="rt-checkmark"></span>
                                        </label>
                                        <span>I have read and agree to the <a href="javascript:void(0);">Terms</a> of Use and <a href="javascript:void(0);">Privacy Policy</a></span>
                                    </div>
                                    <p className="sc-eCImPb hUcdXv" style={errors.agreement ? {display:'block'} : {display:'none'}}>Please accept the agreement</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group m-0">
                                    <button className="rt-btn rt-btn-lg p-0" type="submit">SIGN UP</button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};
import {
    DashboardPage,
    LoginPage,
    SignupClub,
    SignupUser,
    ProfilePage,
    EditProfilePage,
    SettingsPage,
    TrainCategoresPage,
    LearnCategoresPage,
    CategoryDetails,
    CourseDetails,
    VideoDetails,
    OrganizeTraining,
    LeaderBoard,
    MemberProfilePage,
    ProgressPage,
    TeammatesPage,
    ChallengesPage,
    ChallengesDetails,
    SessionDetails, CategoryBmsu, PayPalReturnUser
} from '../view'
import {ForgotPage} from '../view'
import {CreatePage} from '../view'
import PrivateRoutes from './privateRoutes'
import { routes } from './routesConstants'
import {HeaderComponent} from '../components'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import 'axios-progress-bar/dist/nprogress.css'
import {CategorySessions} from "../view";
import {PurchaseSubscription} from "../view/auth/login/purchase-subscription";
const progress = require('axios-progress-bar')


const MainRoutes = () => {
    progress.loadProgressBar();
    return (
            <BrowserRouter>
                <Switch>
                    <Route path={routes.login} exact component={LoginPage} />
                    <Route path={routes.purchaseSubscription} exact component={PurchaseSubscription} />
                    <Route path={routes.forgot} exact component={ForgotPage} />
                    <Route path={routes.create} exact component={CreatePage} />
                    <Route path={routes.signupuser} exact component={SignupUser} />
                    <Route path={routes.signupclub} exact component={SignupClub} />

                    <PrivateRoutes path={routes.dashboard} component={DashboardPage} />
                    <PrivateRoutes path={routes.profile} component={ProfilePage} />
                    <PrivateRoutes path={routes.editprofile} component={EditProfilePage} />
                    <PrivateRoutes path={routes.settings} component={SettingsPage} />

                    <PrivateRoutes path={routes.learncategores} component={LearnCategoresPage} />
                    <PrivateRoutes path={routes.categoryBmsu} component={CategoryBmsu} />

                    <PrivateRoutes path={routes.traincategores} component={TrainCategoresPage} />{/*train-categories*/}
                    <PrivateRoutes path={routes.categorySessions} component={CategorySessions} />
                    <PrivateRoutes path={routes.sessionDetail} component={SessionDetails} />
                    <PrivateRoutes path={routes.drillDetail} component={VideoDetails} />

                    <PrivateRoutes path={routes.coursedetails} component={CourseDetails} />
                    <PrivateRoutes path={routes.videodetails} component={VideoDetails} />
                    <PrivateRoutes path={routes.organizetraining} component={OrganizeTraining} />
                    <PrivateRoutes path={routes.leaderboard} component={LeaderBoard} />
                    <PrivateRoutes path={routes.memberprofile} component={MemberProfilePage} />
                    <PrivateRoutes path={routes.progress} component={ProgressPage} />
                    <PrivateRoutes path={routes.teammates} component={TeammatesPage} />
                    <PrivateRoutes path={routes.challenges} component={ChallengesPage} />
                    <PrivateRoutes path={routes.challengesdetails} component={ChallengesDetails} />

                    <Route path={routes.paypalReturn} component={PayPalReturnUser} />
                </Switch>
            </BrowserRouter>
    )
}

export default MainRoutes